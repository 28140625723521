<!-- PRECONDITION: We assume the passed in advertisement has a timestampedTranscription prop -->

<template>
  <div v-if="advertisement.needsTranscription" class="relative w-full h-full">
    <div class="absolute w-full flex flex-col items-center select-none top-1/2 transform -translate-y-1/2">
      <video style="height: 96px; background: linear-gradient(180deg, rgba(237, 130, 150, 0.00) 34.66%, rgba(237, 130, 150, 0.30) 100%), #FFF; box-shadow: 0px 0px 0px 0px rgba(8, 17, 17, 0.24), 0px 2px 4px 0px rgba(8, 17, 17, 0.24), 0px 6px 6px 0px rgba(8, 17, 17, 0.20), 0px 14px 9px 0px rgba(8, 17, 17, 0.12), 0px 26px 10px 0px rgba(8, 17, 17, 0.04), 0px 40px 11px 0px rgba(8, 17, 17, 0.00);"
      class="rounded-full" preload="metadata" playsinline autoplay muted loop>
        <source src="../../../assets/videos/Blue-Export.webm" type="video/mp4">
      </video>
      <BaseText size="md" class="text-text-muted mt-5 font-medium">
        Generating Transcript
      </BaseText>
      <BaseText size="sm" class="text-text-normal mt-1.5 text-center">
        Hold tight! Our servers will get to this ad soon.
      </BaseText>
    </div>
  </div>
  <div v-else-if="!advertisement.needsTranscription && !this.transcription?.length"
  class="relative w-full h-full">
    <div class="absolute w-full flex flex-col items-center top-1/2 transform -translate-y-1/2">
      <img src="../../../assets/images/silent-video.svg" alt="" />
      <div class="flex flex-col gap-1 text-center mt-6">
        <BaseText type="label" size="sm" class="text-text-muted">
          Shhh... It's Silent!
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          This video contains no spoken word.
        </BaseText>
      </div>
      <div class="flex flex-col items-center gap-2 mt-12">
        <BaseText type="body" size="sm" class="text-text-normal">
          Was this an error?
        </BaseText>
        <button class="px-3 py-1.5 rounded-md" @click="showIntercom"
        style="box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);">
          <BaseText type="label" size="sm" class="text-text-muted">
            Contact Support
          </BaseText>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="w-full h-full flex flex-col">
    <div v-if="hasProductIntro" class="p-4">
      <button class="group product-intro-btn flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md"
      @click="playProductIntro">
        <PlayIcon class="text-icon-normal transition-colors duration-100 group-hover:text-icon-muted" />
        <BaseText type="label" size="sm" class="text-text-muted">
          Product Introduction
        </BaseText>
        <BaseText type="label" size="sm" class="text-text-normal">
          {{ productIntroLabel }}
        </BaseText>
      </button>
    </div>
    <div v-else class="w-full h-2" />
    <div class="relative flex-grow w-full min-h-0 pr-1 pb-14">
      <transition name="fade">
        <div v-if="!isScrollTop" class="top-fade-overlay absolute top-0 left-0 right-0 h-6 z-10 pointer-events-none" />
      </transition>
      <div class="transcript-list h-full w-full flex flex-col pb-1.5" ref="transcript-list">
        <div v-for="(timestamp, index) in transcription" :key="`timestamp-${index}`" class="w-full">
          <component :is="useDynamicLoader ? 'BaseDynamicLoader' : 'div'" observerElmSelector=".transcript-list" :identifier="`timestamp-${index}`" 
          :buffer="500" :enableInitialMountFadeIn="false">
            <div class="group w-full flex pl-4 py-1.5 pr-2 border-l-2 border-transparent cursor-default
            rounded-r-md transition-colors hover:border-text-muted hover:bg-neutral-25">
              <BaseText type="body" size="sm" class="flex-grow text-text-muted leading-6">
                <span class="relative right-0.5 pl-1.5 pr-1 py-0.5 mr-1.5 bg-neutral-25 text-xs font-medium 
                transition-colors group-hover:bg-neutral-50" style="border-radius: 4px">
                  {{ formatTimestampLabel(timestamp) }}
                </span>
                {{ timestamp.sentence }}
              </BaseText>
              <!-- Copy button (on hover) -->
              <div class="w-6 h-6 self-start transition-opacity opacity-0 group-hover:opacity-100">
                <button class="sentence-copy-btn p-0.5 rounded-md" v-clipboard:copy="timestamp.sentence.trim()"
                v-clipboard:success="() => {handleCopySuccess('Section copied')}">
                  <StaticCopyIcon />
                </button>
              </div>
            </div>
          </component>
        </div>
      </div>
      <transition name="fade">
        <div v-if="!isMaxScroll" class="bottom-fade-overlay absolute bottom-14 left-0 right-0 h-6 z-10 pointer-events-none" />
      </transition>
      <!-- Copy transcript options -->
      <div class="copy-transcript-btn flex absolute bottom-4 right-4 bg-white rounded-md z-50">
        <button class="group flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-l-md transition-colors hover:bg-neutral-25"
        v-clipboard:copy="copyFullTranscription(true)" v-clipboard:success="() => {handleCopySuccess('Transcript Copied')}">
          <StaticCopyIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Copy Transcript
          </BaseText>
        </button>
        <button class="group p-1.5 border-l rounded-r-md border-border-normal transition-colors"
        :class="copyOptionsOpen ? 'bg-neutral-25' : 'hover:bg-neutral-25'" 
        @click="copyOptionsOpen = !copyOptionsOpen">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
          class="transform transition" :class="copyOptionsOpen ? 'text-icon-muted' : 'text-icon-normal group-hover:text-icon-muted'"
          :style="{transform: copyOptionsOpen ? 'scaleY(-1)' : 'scaleY(1)'}">
            <path d="M13.25 8.75L10 12.25L6.75 8.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <!-- Extra copy options dropdown -->
        <transition name="quickfade">
          <div v-if="copyOptionsOpen" v-on-clickaway="() => { copyOptionsOpen = false }"
          class="copy-transcript-dropdown flex flex-col gap-0.5 p-1 bg-white">
            <button class="group flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
            v-clipboard:copy="copyFullTranscription(false)" v-clipboard:success="() => {handleCopySuccess('Transcript Text Copied')}">
              <TextOnlyIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText type="label" size="sm" class="text-text-normal transition-colors group-hover:text-text-muted">
                Text Only
              </BaseText>
            </button>
            <button class="group flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
            v-clipboard:copy="copyFullTranscription(true)" v-clipboard:success="() => {handleCopySuccess('Transcript Copied')}">
              <StopwatchIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText type="label" size="sm" class="text-text-normal transition-colors group-hover:text-text-muted">
                With Timestamps
              </BaseText>
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

// Icons
import PlayIcon from '../../globals/Icons/PlayIcon.vue'
import StaticCopyIcon from '../../globals/Icons/StaticCopyIcon.vue'
import TextOnlyIcon from '../../globals/Icons/TextOnlyIcon.vue'
import StopwatchIcon from '../../globals/Icons/StopwatchIcon.vue'

export default {
  name: 'Transcript',
  mixins: [clickaway],
  components: {
    PlayIcon,
    StaticCopyIcon,
    TextOnlyIcon,
    StopwatchIcon
  },
  props: {
    advertisement: {
      type: Object,
      required: true
    },
    carouselIndex: {
      type: Number,
      default: 0
    },
    assetMetadata: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      copyOptionsOpen: false,

      // Scroll UI states
      scrollTimeout: null,
      isScrollTop: true,
      isMaxScroll: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs['transcript-list'].addEventListener('scroll', this.handleTranscriptSroll)
    })
  },
  beforeDestroy () {
    this.$refs['transcript-list'].removeEventListener('scroll', this.handleTranscriptSroll)
  },
  computed: {
    transcription () {
      if (this.advertisement?.type === 'dco') {
        return this.advertisement?.cards?.[this.carouselIndex]?.timestampedTranscription || null
      }
      return this.advertisement?.timestampedTranscription || null
    },
    hasProductIntro () {
      return this.advertisement?.timeProductWasMentioned
        && this.advertisement.timeProductWasMentioned > 0
        && (
          this.advertisement?.type === 'video' 
          || (this.advertisement?.type === 'dco' && this.advertisement?.cards?.[0]?.type === 'video' && this.carouselIndex === 0)
        )
    },
    productIntroLabel() {
      if (!this.hasProductIntro || !this.assetMetadata.duration) return null
      const videoDuration = Math.round(this.assetMetadata.duration)

      const timeProductWasMentioned = Math.round(this.advertisement.timeProductWasMentioned)
      const percentage = (timeProductWasMentioned / videoDuration) * 100
      const timeMentionedMinutes = Math.floor(timeProductWasMentioned / 60)
      const timeMentionedSeconds = Math.floor(timeProductWasMentioned % 60)
      const minutes = timeMentionedMinutes < 10 ? `0${timeMentionedMinutes}` : timeMentionedMinutes
      const seconds = timeMentionedSeconds < 10 ? `0${timeMentionedSeconds}` : timeMentionedSeconds

      return `${minutes}:${seconds} (${percentage.toFixed()}% into video)`
    },
    useDynamicLoader () {
      // We will use the BaseDynamicLoader component for exceedingly large transcripts
      return this.transcription?.length > 200
    }
  },
  methods: {
    copyFullTranscription (includeTimestamps) {
      if (!this.transcription) {
        return ''
      }
      // Check if we should include timestamps
      if (includeTimestamps) {
        return this.transcription
          .map((t) => `${this.formatTimestampLabel(t)}\n${t.sentence.trim()}\n`)
          .join('\n')
      } else {
        return this.transcription
          .map((t) => t.sentence.trim())
          .join('\n')
      }
    },
    handleCopySuccess (message) {
      if (this.copyOptionsOpen) this.copyOptionsOpen = false
      this.$showAlert({
        message,
        type: 'success'
      })
    },
    showIntercom () {
      window.Intercom('show')
    },
    playProductIntro () {
      const timeMentioned = this.advertisement.timeProductWasMentioned
      if (!timeMentioned) return
      this.$emit('playProductIntro', timeMentioned)
    },
    formatTimestampLabel(timestamp) {
      const { startTime: start, endTime: end } = timestamp
      const startMinutes = Math.floor(start / 60)
      const startSeconds = Math.floor(start % 60)
      const endMinutes = Math.floor(end / 60)
      const endSeconds = Math.floor(end % 60)
      const startMinutesFormatted = startMinutes < 10 ? `0${startMinutes}` : startMinutes
      const startSecondsFormatted = startSeconds < 10 ? `0${startSeconds}` : startSeconds
      const endMinutesFormatted = endMinutes < 10 ? `0${endMinutes}` : endMinutes
      const endSecondsFormatted = endSeconds < 10 ? `0${endSeconds}` : endSeconds
      return `${startMinutesFormatted}:${startSecondsFormatted}-${endMinutesFormatted}:${endSecondsFormatted}`
    },
    handleTranscriptSroll () {
      const scrollableElm = this.$refs['transcript-list']
      this.isScrollTop = scrollableElm.scrollTop === 0
      this.isMaxScroll = Math.ceil(scrollableElm.scrollHeight - (scrollableElm.scrollTop)) <= scrollableElm.clientHeight

      // Handle scrollbar fade transitions
      scrollableElm.style.setProperty('--scrollbar-thumb-bg', '#DFE1E7')
      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(() => {
        scrollableElm.style.setProperty('--scrollbar-thumb-bg', 'white')
      }, 500)
    }
  }
}
</script>

<style scoped>
.product-intro-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.product-intro-btn:hover {
  box-shadow: none;
  background-color: #F6F8FA; /* neutral-25 */
}
.copy-transcript-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.10), 0px 0px 0px 1px rgba(0, 56, 108, 0.06);
}
.copy-transcript-dropdown {
  position: absolute;
  bottom: calc(100% + 8px);
  right: 0px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.transcript-list {
  overflow-y: scroll;
  transition: --scrollbar-thumb-bg 250ms ease-in-out;
}
.sentence-copy-btn {
  color: #5E6678; /* text-icon-normal */
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out, opacity 75ms ease-in-out, box-shadow 150ms ease-in-out;
}
.sentence-copy-btn:hover, .sentence-copy-btn:active {
  color: #303546; /* text-icon-muted */
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.sentence-copy-btn:active {
  opacity: 0.5;
}

/* Custom scrollbar classes */
.transcript-list::-webkit-scrollbar {
  width: 3px;
}
.transcript-list::-webkit-scrollbar-thumb {
  /* We use an animated CSS variable to apply the scrollbar fading transitions */
  /* This is because scrollbar pseudo-elements do not support the 'transition' property */
  background-color: var(--scrollbar-thumb-bg);
  border-radius: 18px;
}
.top-fade-overlay {
  background: linear-gradient(180deg, #FFFFFF, transparent);
}
.bottom-fade-overlay {
  background: linear-gradient(0deg, #FFFFFF, transparent);
}

/* ========= Vue <transition> classes ========= */
.fade-enter-active, .fade-leave-active {
  transition: opacity 150ms ease-in-out;
}
.quickfade-enter-active, .quickfade-leave-active {
  transition: opacity 100ms ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to, .quickfade-enter-from, .quickfade-enter, .quickfade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from, .quickfade-enter-to, .quickfade-leave-from {
  opacity: 1;
}
</style>