<template>
  <div class="w-full flex flex-col px-4 pt-3">
    <!-- Section header -->
    <div class="w-full flex items-center gap-3 p-1">
      <BrainIcon class="text-icon-normal" />
      <BaseText type="label" size="sm" class="flex-grow text-text-muted">
        AI Analysis
      </BaseText>
      <!-- TODO: Implement later - Will route to video course -->
      <!-- <button class="flex items-center">
        <BaseText type="label" size="sm" class="text-text-muted">
          Learn more
        </BaseText>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="text-icon-normal">
          <path d="M8.33331 13.3327L11.0774 10.5886C11.4028 10.2632 11.4028 9.73553 11.0774 9.41009L8.33331 6.66602" 
          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button> -->
    </div>
    <!-- Loading state for AI analysis data -->
    <div v-if="!emotionalDrivers" class="relative mx-auto" style="width: 416px; height: 264px;">
      <div class="w-full flex flex-col items-center mt-12">
        <DetailsLoaderAnimatedIcon :width="84" :height="84" :color="getTheme" />
        <BaseText type="label" size="sm" class="text-text-muted mt-6">
          We're still analyzing this ad
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal mt-1">
          Check back later for additional insights
        </BaseText>
      </div>
    </div>
    <!-- Emotional drivers radar chart -->
    <div v-else class="relative mx-auto" style="width: 416px; height: 264px;">
      <div id="radar-chart-container" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      style="width: 138px; height: 138px; min-height: 0 !important">
        <div id="emotional-drivers-chart" class="pointer-events-none" />
        <RadarChartBackgroundWheel class="absolute left-0 right-0 top-0 bottom-0" style="z-index: -10" />        
      </div>
      <!-- Emotional Driver labels / values -->
      <div v-for="(eDriver, index) in emotionalDrivers" :key="eDriver.name" style="width: 64px"
      class="absolute flex flex-col items-center gap-1" :class="`ed-label-${index}`">
        <div class="px-1" style="border-radius: 3px" 
        :class="eDriver.isHighlighted ? `bg-primary-${getTheme}-25` : 'bg-neutral-25'">
          <div class="ed-value" :class="eDriver.isHighlighted ? `text-primary-${getTheme}-300` : 'text-text-muted'">
            {{ eDriver.value }}
          </div>
        </div>
        <div class="ed-text text-text-muted whitespace-nowrap">
          {{ eDriver.name }}
        </div>
      </div>
    </div>
    <!-- Content style -->
    <div class="w-full flex gap-2 justify-between items-center p-1">
      <BaseText type="body" size="sm" class="text-text-normal">
        Content Style
      </BaseText>
      <div class="flex items-center gap-1.5 pl-1.5 pr-2 py-1 rounded-md" :class="`bg-primary-${getTheme}-10`">
        <ContentStyleIcon :class="`text-primary-${getTheme}-300`" />
        <BaseText type="label" size="sm" :class="`text-primary-${getTheme}-300`">
          {{ contentStyle }}
        </BaseText>
      </div>
    </div>
    <!-- Creative Target -->
    <div class="w-full flex flex-col p-1 mt-3" :class="`bg-primary-${getTheme}-10`" style="border-radius: 10px">
      <div class="w-full flex items-center gap-1 p-2">
        <CreativeTargetIcon :class="`text-primary-${getTheme}-300`" />
        <BaseText type="label" size="sm" :class="`text-primary-${getTheme}-300`">
          Creative Targeting
        </BaseText>
      </div>
      <div class="w-full flex items-center gap-2 px-2.5 py-2 rounded-md bg-white">
        <BaseText type="body" size="sm" class="flex-grow text-text-muted capitalize">
          {{ advertisement?.creativeTargeting || 'Unknown' }}.
        </BaseText>
        <button v-if="advertisement?.creativeTargeting" class="flex-shrink-0"
        @click="seeMoreCreativeTargeting">
          <BaseText type="label" size="sm" class="text-text-muted">
            See more
          </BaseText>
        </button>
      </div>
    </div>
    <!-- Persona (TODO) -->
    <div class="w-full flex flex-col p-1 mt-3" :class="`bg-primary-${getTheme}-10`" style="border-radius: 10px">
      <div class="w-full flex items-center gap-1 p-2">
        <AdPersonaIcon :class="`text-primary-${getTheme}-300`" />
        <BaseText type="label" size="sm" :class="`text-primary-${getTheme}-300`">
          Persona
        </BaseText>
      </div>
      <div class="w-full flex items-center gap-4 px-2.5 py-2 rounded-md bg-white">
        <div class="flex items-center gap-2">
          <BaseText type="body" size="sm" class="text-text-normal">
            Age:
          </BaseText>
          <BaseText type="label" size="sm" class="text-text-muted capitalize">
            {{ advertisement?.persona?.age || 'Unknown' }}
          </BaseText>
        </div>
        <div class="flex items-center gap-2">
          <BaseText type="body" size="sm" class="text-text-normal">
            Gender:
          </BaseText>
          <BaseText type="label" size="sm" class="text-text-muted capitalize">
            {{ advertisement?.persona?.gender || 'Unknown' }}
          </BaseText>
        </div>
      </div>
    </div>
    <!-- DCO Extra info tip -->
    <div v-if="advertisement?.type === 'dco'" class="w-full flex items-center gap-1.5 p-1.5 mt-3 rounded-md bg-neutral-25">
      <InfoResponseIcon class="text-icon-normal" />
      <BaseText type="body" size="sm" class="text-text-muted">
        Analysis for DCO Ads only supports the first ad variant.
      </BaseText>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApexCharts from 'apexcharts'

// Icons
import BrainIcon from '../../globals/Icons/BrainIcon.vue'
import ContentStyleIcon from '../../globals/Icons/ContentStyleIcon.vue'
import CreativeTargetIcon from '../../globals/Icons/CreativeTargetIcon.vue'
import AdPersonaIcon from '../../globals/Icons/AdPersonaIcon.vue'
import RadarChartBackgroundWheel from './RadarChartBackgroundWheel.vue'
import DetailsLoaderAnimatedIcon from '../../globals/Icons/DetailsLoaderAnimatedIcon.vue'
import InfoResponseIcon from '../../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

export default {
  name: 'AIAnalysis',
  components: {
    BrainIcon,
    ContentStyleIcon,
    CreativeTargetIcon,
    AdPersonaIcon,
    RadarChartBackgroundWheel,
    DetailsLoaderAnimatedIcon,
    InfoResponseIcon
  },
  props: {
    advertisement: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      emotionalDrivers: null
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    contentStyle () {
      if (!this.advertisement.contentFilter) return 'Other'
      // Find the contentFilter key with the highest value (that isn't other)
      return Object.entries(this.advertisement.contentFilter)
        .filter(([key]) => key !== 'other')
        .sort((a, b) => b[1] - a[1])[0][0]
    },
    maxChartValue () {
      return this.emotionalDrivers?.reduce((max, driver) => Math.max(max, driver.value), 70)
    }
  },
  watch: {
    advertisement () {
      const series = this.computeEmotionalDriverSeries()
      if (!series) {
        if (this.chart) {
          this.chart.destroy()
          this.chart = null
        }
        return
      }

      if (this.chart) { // Update the chart's series
        this.chart.updateOptions({ yaxis: { show: false, max: this.maxChartValue } })
        this.chart.updateSeries([{
          name: 'Emotional Drivers',
          data: series
        }])
      } else {
        this.initializeRadarChart(series)
      }
    }
  },
  mounted () {
    // Initialize the chart
    const series = this.computeEmotionalDriverSeries()
    this.initializeRadarChart(series)
  },
  methods: {
    seeMoreCreativeTargeting () {
      if (!this.advertisement?.creativeTargeting) return
      const searchString = this.advertisement.creativeTargeting.replace(/\./g, '')
      this.$router.push({ name: 'DiscoveryView', query: { search: searchString }})
    },
    computeEmotionalDriverSeries () {
      const emotionalDrivers = this.advertisement?.emotionalDrivers
      if (!emotionalDrivers) {
        this.emotionalDrivers = null
        return null
      }

      let lowestHighlightedValue = 10
      let sortedEmotionalDrivers = Object.entries(emotionalDrivers).sort((a, b) => b[1] - a[1]).slice(0, 6)
        .map(([key, value], index) => {
          if (index === 2) lowestHighlightedValue = value
          const isHighlighted = value > 1 && (index <= 2 || value === lowestHighlightedValue)
          return {
            name: key,
            value: value * 10,
            isHighlighted: isHighlighted
          }
        }
      )
      // We rearrange the order to make the chart more visually appealing
      // [0, 1, 2, 3, 4, 5] -> [0, 3, 1, 4, 2, 5]
      sortedEmotionalDrivers = [
        sortedEmotionalDrivers[0],
        sortedEmotionalDrivers[3],
        sortedEmotionalDrivers[1],
        sortedEmotionalDrivers[4],
        sortedEmotionalDrivers[2],
        sortedEmotionalDrivers[5]
      ]
      this.emotionalDrivers = sortedEmotionalDrivers
      return this.emotionalDrivers.map(driver => driver.value)
    },
    initializeRadarChart (series) {
      if (!this.emotionalDrivers) return
      const options = {
        series: [{
          name: 'Emotional Drivers',
          data: series
        }],
        chart: {
          type: 'radar',
          width: 200,
          height: 200,
          toolbar: { show: false }
        },
        tooltip: { enabled: false },
        legend: { enabled: false },
        yaxis: { 
          show: false,
          max: this.maxChartValue
        },
        xaxis: { labels: { show: false } },
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: 'transparent',
              connectorColors: 'transparent',
            }
          }
        },
        colors: [this.getRadarFillColor()],
        fill: { opacity: 0.2 }
      }
      this.$nextTick(() => {
        const chartContainer = document.getElementById('emotional-drivers-chart')
        this.chart = new ApexCharts(chartContainer, options)
        this.chart.render()
      })
    },
    getRadarFillColor () {
      switch (this.getTheme) {
        case 'blue': return '#1F69FF'
        case 'purple': return '#6534DF'
        case 'red': return '#ED615A'
        case 'green': return '#00A878'
        default: return '#1F69FF'
      }
    }
  }
}

</script>

<style script>
#emotional-drivers-chart {
  /* Some fine-tuning of the size/positioning of the chart so it lines up with our circular grid bg */
  position: absolute;
  left: calc(50% - 1.25px);
  top: calc(50% + 11.5px);
  transform: translate(-50%, -50%) scale(88.5%, 88%);
}

/* Emotional Driver Label text styling */
.ed-value {
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: 0.6px;
}
.ed-text {
  text-align: center;
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
/* Emotional driver label positioning */
.ed-label-0 {
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
}
.ed-label-1 {
  right: 52px;
  top: 64px;
}
.ed-label-2 {
  right: 52px;
  bottom: 52px;
}
.ed-label-3 {
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}
.ed-label-4 {
  left: 52px;
  bottom: 52px;
}
.ed-label-5 {
  left: 52px;
  top: 64px;
}
</style>